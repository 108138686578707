<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <v-stepper v-model="step">
          <v-stepper-header>
            <v-stepper-step :complete="step > 1" step="1">
              {{ $t("tag.picker.step1") }}
            </v-stepper-step>
            <v-divider></v-divider>
            <v-stepper-step :complete="step > 2" step="2">
              {{ $t("tag.picker.step2") }}
            </v-stepper-step>
          </v-stepper-header>

          <v-stepper-items>
            <v-stepper-content step="1">
              <node-selector v-model="localTag" />

              <v-row>
                <v-spacer></v-spacer>
                <v-btn
                  color="primary"
                  text
                  @click="step = 2"
                  x-large
                  :disabled="localTag == null"
                >
                  {{ $t("conditionTree.chooseSensor") }}
                  <v-icon right>mdi-arrow-right</v-icon>
                </v-btn>
              </v-row>
            </v-stepper-content>

            <v-stepper-content step="2">
              <v-row>
                <v-col
                  :cols="12"
                  :md="3"
                  :sm="6"
                  v-for="(v, i) in keys"
                  :key="i"
                >
                  <selection-sheet
                    v-model="localTagKey"
                    :item="v"
                    color="grey"
                    item-key="key"
                    item-label="label"
                    icon-key="mdi-key"
                  />
                </v-col>
              </v-row>

              <v-row>
                <v-spacer></v-spacer>
                <v-btn color="grey" text @click="step = 1" x-large>
                  <v-icon left>mdi-arrow-left</v-icon>
                  {{ $t("common.previousStep") }}
                </v-btn>
              </v-row>
            </v-stepper-content>
          </v-stepper-items>
        </v-stepper>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions } from "vuex";
import SelectionSheet from "@/components/common/SelectionSheet";
import NodeSelector from "./NodeSelector.vue";
export default {
  components: { SelectionSheet, NodeSelector },
  name: "DeviceSelection",

  props: {
    tagKey: {
      default: null,
    },

    tag: {
      default: null,
    },
  },

  data() {
    return {
      localTag: null,
      localTagKey: null,
      keys: [],
      step: 1,
    };
  },

  methods: {
    ...mapActions("tag", ["getKeysForTagAsync", "getTagWithInformation"]),
  },

  computed: {},

  watch: {
    async localTag(v) {
      if (v) {
        var t = await this.getTagWithInformation(v);
        this.keys = await this.getKeysForTagAsync({ tagId: v });
        this.$emit("update-tag", v);
        this.$emit("update-name", t ? t.name : v);
      } else {
        this.$emit("update-tag", v);
        this.$emit("update-name", v);
      }
    },

    localTagKey(v) {
      var keyObj = this.keys.find((e) => e.key == v);
      this.$emit(
        "update-tag-key-label",
        keyObj && keyObj.label ? keyObj.label : v
      );
      this.$emit("update-tag-key", v);
    },

    async tag(v) {
      this.localTag = v;

      if (v) this.keys = await this.getKeysForTagAsync({ tagId: v });
    },

    tagKey(v) {
      this.localTagKey = v;
    },
  },

  async created() {
    this.localTag = this.tag;
    this.localTagKey = this.tagKey;
  },
};
</script>

